<template>
    <div class="Message xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>消息通知</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personal-left">
                    <hd-img></hd-img>
                </div>
                <div class="personal-right">
                    <div class="personal-right-tit">
                        <i class="iconfont">&#xe62c;</i><span>消息<em>通知({{ MessageTotal }})</em></span>
                        <!--<el-link type="danger" :underline="false" class="el-icon-delete" @click="deleteAll"></el-link>-->
                    </div>
                    <!--    内容    -->
                    <div class="center-content">
                        <ul class="msg-list">
                            <li class="msg-item noread clearfix" v-for="(MessageItem, i) in MessageList"
                                @click="toDetail(i)">
                                <h4 class="left">
                                    <i>●</i>
                                    <span>【{{ MessageItem.Type }}】</span>
                                    {{ MessageItem.title }}
                                </h4>
                                <p class="right">{{ MessageItem.createTime }}</p>
                            </li>
                            <el-pagination @current-change="ChangePage" :page-size="15" :current-page="PageIndex"
                                :total="MessageTotal" layout="total, prev, pager, next, jumper"></el-pagination>
                        </ul>

                    </div>
                    <!--    详情    -->
                    <div class="msg-detail">
                        <el-page-header @back="goBack"></el-page-header>
                        <h3 class="mess-tit">
                            <p>{{ MessageDetail.title }}</p>
                        </h3>
                        <div class="mess-info">
                            <p>{{ MessageDetail.content }}</p>
                            <div class="item-img" v-if="MessageDetail.atta && MessageDetail.atta.length > 0">
                                <img v-for="(item, index) in MessageDetail.atta"
                                    :src="$basePath.Authorization + item.filePath" @click="handleImagePreview(item)">
                            </div>
                            <el-dialog :visible.sync="Image.dialogVisible">
                                <img width="100%" :src="$basePath.Authorization + Image.dialogImageUrl" alt="">
                            </el-dialog>
                        </div>
                    </div>
                    <!-- <div id="page">
                        <el-pagination background
                                        layout="prev, pager, next,total"
                                        :page-size="10"
                                        @current-change="handleCurrentChange"
                                        :current-page.sync="currentPage"
                                        :total="total">
                        </el-pagination>
                    </div> -->
                </div>
            </div>
        </div>
        <ft-com></ft-com>
    </div>
</template>

<script>
import { MessageDomain } from '../../components/personal/Message';
import ftCom from '../../components/footer'
import nhCom from "../../components/navheader";
import hdImg from "../../components/headerImg";
var pageIndex = 1;
var keywordVal = ''
export default {
    name: "Message",
    components: {
        ftCom,
        nhCom,
        hdImg
    },
    data() {
        var MessageDomainTemp = new MessageDomain(this.TokenClient, this.Services.Authorization);
        return {
            labelPosition: 'right',
            MessageList: [],
            MessageDetail: {},
            total: 0,
            currentPage: 1, //初始页
            keyword: '',
            PageIndex: 1,
            PageTotal: 1,
            MessageTotal: 0,
            rloading: [],//loading遮罩
            Result: MessageDomainTemp,
            Image: {
                //单击图片上传控件中的图片时，放大显示
                dialogVisible: false,
                dialogImageUrl: '',
            },
        };
    },
    mounted() {
        this.AllMessages();
    },
    methods: {
        handleImagePreview(file) {
            this.Image.dialogImageUrl = file.filePath;
            this.Image.dialogVisible = true;
        },
        //返回列表页
        goBack() {
            $('.center-content').show();
            $('.personal-right-tit').show();
            $('.msg-detail').hide();

        },
        //分页点击事件
        handleCurrentChange: function (pageNum) {
            this.currentPage = pageNum;
            pageIndex = pageNum;
            this.AllMessages();
        },
        toDetail(i) {
            var _this = this;
            $('.center-content').hide();
            $('.personal-right-tit').hide();
            $('.msg-detail').show();
            _this.Result.MessageDetails(_this.MessageList[i].id, function (data) {
                _this.MessageDetail = data.data;
            }, function (error) {
                console.log(error);
            })
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.AllMessages();
        },
        AllMessages() {
            var _this = this;
            _this.rloading = this.openLoading();
            _this.MessageList = [];
            _this.Result.AllMessage(_this.PageIndex, function (data) {
                if (data.data) {
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageTotal = data.data.pageCount;
                    _this.MessageTotal = data.data.dataTotal;
                    _this.MessageList = data.data.results;
                    data.data.results.forEach(function (item, index) {
                        if (_this.MessageList[index].createTime) {
                            _this.MessageList[index].createTime = item.createTime.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ').slice(0, item.createTime.indexOf("."));
                        }
                        switch (item.categoryId) {
                            case 1:
                                {
                                    _this.MessageList[index].Type = "系统消息"
                                    break;
                                }
                            case 2: {
                                _this.MessageList[index].Type = "机构消息"
                                break;
                            }
                            case 3: {
                                _this.MessageList[index].Type = "平台消息"
                                break;
                            }
                        }
                    });

                }
            },
                function (err) {
                    console.log(err);
                }
            )
            _this.rloading.close()
        }
    }
}
</script>

<style scoped>
@import "../../assets/css/footer.css";

#page {
    text-align: center;
    padding: 100px 0 30px;
}

.banner {
    width: 100%;
    height: 160px;
    background: url("../../assets/img/news_banner.jpg") center no-repeat;
}

.banner-wrap {
    position: relative;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}

.banner-text {
    float: left;
}

.banner-text h3 {
    padding-top: 35px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 14px;
    color: #ffffff;
    margin-top: 20px;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

/**/
.personal-content {
    background: #f7f7f7;
    padding: 40px 0;
}

.personal-box {
    width: 1200px;
    margin: 0 auto;
}

.personal-left {
    float: left;
}

.personal-right {
    float: right;
    width: 900px;
    min-height: 740px;
    background: #FFFFFF;
    box-sizing: border-box;
}

.personal-right-tit {
    border-bottom: 1px solid #e5e5e5;
    padding: 20px;
}

.personal-right-tit .iconfont {
    color: #04a290;
    font-size: 24px;
    margin-right: 5px;
}

.personal-right-tit span {
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    color: #333333;
}

.personal-right-tit span em {
    font-style: normal;
    color: #04a290;
}

.personal-right-tit .el-icon-delete {
    float: right;
    font-size: 18px;
}

.msg-list {
    padding: 0 20px;
}

.msg-list .msg-item {
    padding: 8px 0;
    border-bottom: 1px dashed #e5e5e5;
    line-height: 40px;
    height: 40px;
}

.msg-list .msg-item h4 {
    color: #999999;
    font-weight: normal;
    width: 700px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.msg-list .msg-item.noread h4 {
    color: #333333;
}

.msg-list .msg-item p {
    color: #999999;
}

.msg-detail {
    padding: 20px;
    display: none;
}

.msg-detail .mess-tit {
    text-align: center;
}

.msg-detail .mess-info p {
    text-indent: 28px;
}
.mess-info  .item-img {
    margin-right: 10px;
    display: flex;
    justify-content: flex-start;
    justify-items: center;
    flex-wrap: wrap;
}
.item-img img {
    width: 118px;
    height: 118px;
}
</style>
